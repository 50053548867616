<template>
  <div class="infoGridBlock">
    <b v-if="infoTitle" :class="'ml-2 ' + infoTitleClass">{{ infoTitle }}</b>
    <v-btn
      elevation="0"
      v-if="buttonTag"
      class="ms-3"
      x-small
      @click="emitButtonEvent"
      >{{ buttonTag }}</v-btn
    >
    <v-btn
      v-if="buttonShow"
      x-small
      @click="emitButtonShowClick"
      elevation="0"
      class="ms-1"
    >
      <v-icon small color="grey darken-1">mdi-format-paint</v-icon>
    </v-btn>
    <v-card
      v-for="(info, i) in infos"
      :key="i"
      elevation="0"
      class="coloredBottom"
      style="padding-top: 1px; padding-bottom: 1px; padding-left: 10px"
    >
      <v-card-text style="padding: 1px; margin: 0px">
        <v-row cols="2" class="me-2 my-1" v-if="hasSubTitles">
          <div class="ms-2 mt-3">
            <span class="text-h6 font-weight-regular font-weight-light">
              {{ getSubTitle(i) }}
            </span>
          </div>
        </v-row>

        <v-row no-gutters>
          <v-col
            v-for="(item, j) in info"
            :key="j"
            class="mb-2 pa-2"
            :cols="cols"
          >
            <div>
              <div
                :class="
                  item.valueClass
                    ? item.valueClass + ' ' + valueClass
                    : 'black--text font-weight-light ' + valueClass
                "
              >
                <div class="d-flex align-center">
                  <v-icon
                    class="ml-2 mr-1"
                    :color="item.variant"
                    v-if="item.labelIcon"
                  >
                    {{ item.labelIcon }}
                  </v-icon>
                  {{ item.value }}
                  <div v-if="item.valuePct != undefined">
                    <div
                      v-if="item.valuePct > 0"
                      class="d-flex mt-2 ms-2 green--text"
                    >
                      <span class="text-caption"> {{ item.valuePct }}% </span>
                      <v-icon small color="green"> mdi-arrow-up </v-icon>
                    </div>
                    <div
                      v-else-if="item.valuePct < 0"
                      class="d-flex mt-2 ms-2 red--text"
                    >
                      <span class="text-caption"> {{ item.valuePct }}% </span>
                      <v-icon small color="red"> mdi-arrow-down </v-icon>
                    </div>
                    <div v-else class="d-flex mt-2 ms-2">
                      <span class="text-caption"> 0% </span>
                      <v-icon small color=""> mdi-arrow-up </v-icon>
                      <v-icon small color=""> mdi-arrow-down </v-icon>
                    </div>
                  </div>
                </div>
              </div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <p
                    :class="'font-weight-light'"
                    :style="'margin: 0px;'"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ item.label }}
                  </p>
                </template>
                <span>{{ item.description }}</span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<style scoped></style>

<script>
export default {
  name: "InfoGrid",
  props: {
    infos: Array,
    infoTitle: String,
    infoTitleClass: String,
    valueClass: String,
    columns: Number,
    buttonTag: String,
    buttonShow: Boolean,
    subTitles: Array,
  },
  data() {
    return {
      cols: this.columns ? this.columns : 3,
      hasSubTitles: this.subTitles ? true : false,
    };
  },
  methods: {
    emitButtonEvent() {
      this.$emit("buttonClick");
    },
    emitButtonShowClick() {
      this.$emit("buttonShowClick");
    },
    getSubTitle(index) {
      return this.subTitles?.[index] ? this.subTitles?.[index] : "";
    },
  },
};
</script>
