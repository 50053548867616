export function unPackAndProcessCashflowKeys(keys) {
  return keys?.map((k) => {
    if (k.startsWith("202") || k.startsWith("201") || k.startsWith("199")) {
      return {
        text: new Date(k)
          .toLocaleString("en-US", {
            timeZone: "America/Argentina/Buenos_Aires",
          })
          .split(",")[0],
        value: k,
      };
    }
    return {
      text: k,
      value: k,
    };
  });
}

export function parseRecord(r) {
  let obj = {};
  let keys = Object.keys(r);

  for (let i = 0; i < keys.length; i++) {
    const k = keys[i];
    if (k.includes("_datetime") === true) {
      //
      obj[k] = new Date(r[k]);
    } else {
      obj[k] = r[k];
    }
  }
  return obj;
}

export function createInfoGridForEstablishment(farm) {
  //state;
  let infoGrid = [];
  let row1 = [];
  let row2 = [];

  row1.push({
    label: "Gerente",
    value: farm.establishment_manager,
    valueClass: "black--text text--darken-2 h1 display-1",
    labelIcon: "mdi-account",
    variant: "primary",
  });

  row1.push({
    label: "Zona",
    value: farm.zone,
    valueClass: "black--text text--darken-2 h1 display-1",
    labelIcon: "mdi-earth",
    variant: "primary",
  });

  row1.push({
    label: "Subzona",
    value: farm.subzone,
    valueClass: "black--text text--darken-2 h1 display-1",
    labelIcon: "mdi-map-marker",
    variant: "primary",
  });

  row1.push({
    label: "Hectáreas Totales",
    value: farm.hectares,
    valueClass: "black--text text--darken-2 h1 display-1",
    labelIcon: "mdi-home-outline",
    variant: "primary",
  });

  row2.push({
    label: "Hectáreas Soja 1",
    value: farm.Soy_1 * farm.hectares,
    valueClass: "black--text text--darken-2 h1 display-1",
    labelIcon: "mdi-seed",
    variant: "primary",
  });

  row2.push({
    label: "Hectáreas Soja 2",
    value: farm.Soy_2 * farm.hectares,
    valueClass: "black--text text--darken-2 h1 display-1",
    labelIcon: "mdi-seed",
    variant: "primary",
  });

  row2.push({
    label: "Hectáreas Trigo",
    value: farm.Wheat * farm.hectares,
    valueClass: "black--text text--darken-2 h1 display-1",
    labelIcon: "mdi-barley",
    variant: "primary",
  });

  row2.push({
    label: "Hectáreas Cebada",
    value: farm.Barley * farm.hectares,
    valueClass: "black--text text--darken-2 h1 display-1",
    labelIcon: "mdi-barley",
    variant: "primary",
  });

  infoGrid.push(row1);
  infoGrid.push(row2);
  return infoGrid;
}

export function createInfoGridYieldsForEstablishment(farm) {
  //state;
  let infoGrid = [];
  let row1 = [];
  let row2 = [];
  let row3 = [];
  let row4 = [];

  row1.push({
    label: "Soja 1 Rinde Min.",
    value: farm.Soy_1_yield[0] + " tn/ha.",
    valueClass: "black--text text--darken-2 h1",
    labelIcon: "mdi-seed",
    variant: "primary",
  });

  row1.push({
    label: "Soja 1 Rinde Med.",
    value: farm.Soy_1_yield[1] + " tn/ha.",
    valueClass: "black--text text--darken-2 h1",
    labelIcon: "mdi-seed",
    variant: "primary",
  });

  row1.push({
    label: "Soja 1 Rinde Max.",
    value: farm.Soy_1_yield[2] + " tn/ha.",
    valueClass: "black--text text--darken-2 h1",
    labelIcon: "mdi-seed",
    variant: "primary",
  });

  row2.push({
    label: "Soja 2 Rinde Min.",
    value: farm.Soy_2_yield[0] + " tn/ha.",
    valueClass: "black--text text--darken-2 h1",
    labelIcon: "mdi-seed",
    variant: "primary",
  });

  row2.push({
    label: "Soja 2 Rinde Med.",
    value: farm.Soy_2_yield[1] + " tn/ha.",
    valueClass: "black--text text--darken-2 h1",
    labelIcon: "mdi-seed",
    variant: "primary",
  });

  row2.push({
    label: "Soja 2 Rinde Max.",
    value: farm.Soy_2_yield[2] + " tn/ha.",
    valueClass: "black--text text--darken-2 h1",
    labelIcon: "mdi-seed",
    variant: "primary",
  });

  row3.push({
    label: "Trigo Rinde Min.",
    value: farm.Wheat_yield[0] + " tn/ha.",
    valueClass: "black--text text--darken-2 h1",
    labelIcon: "mdi-barley",
    variant: "primary",
  });

  row3.push({
    label: "Trigo Rinde Med.",
    value: farm.Wheat_yield[1] + " tn/ha.",
    valueClass: "black--text text--darken-2 h1",
    labelIcon: "mdi-barley",
    variant: "primary",
  });

  row3.push({
    label: "Trigo Rinde Max.",
    value: farm.Wheat_yield[2] + " tn/ha.",
    valueClass: "black--text text--darken-2 h1",
    labelIcon: "mdi-barley",
    variant: "primary",
  });

  row4.push({
    label: "Cebada Rinde Min.",
    value: farm.Barley_yield[0] + " tn/ha.",
    valueClass: "black--text text--darken-2 h1",
    labelIcon: "mdi-barley",
    variant: "primary",
  });

  row4.push({
    label: "Cebada Rinde Med.",
    value: farm.Barley_yield[1] + " tn/ha.",
    valueClass: "black--text text--darken-2 h1",
    labelIcon: "mdi-barley",
    variant: "primary",
  });

  row4.push({
    label: "Cebada Rinde Max.",
    value: farm.Barley_yield[2] + " tn/ha.",
    valueClass: "black--text text--darken-2 h1",
    labelIcon: "mdi-barley",
    variant: "primary",
  });
  infoGrid.push(row1);
  infoGrid.push(row2);
  infoGrid.push(row3);
  infoGrid.push(row4);
  return infoGrid;
}
