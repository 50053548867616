var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.cashflowColumns)?_c('table',{staticClass:"cashflow-table mr-2 ml-2"},[_c('tr',_vm._l((_vm.cashflowColumns),function(col,i){return _c('th',{key:i,class:{
          rborder: true,
          'cashflow-table-header-cell': true,
          'sticky-left': i === 0,
          'first-col': i === 0,
          'sticky-top': true,
        }},[(col.tooltipText)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(col.text))])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(col.tooltipText))])]):_c('span',{staticClass:"header-span"},[_vm._v(" "+_vm._s(col.text)+" ")])],1)}),0),_c('tbody',_vm._l((_vm.cashflowRows),function(row){return _c('tr',{key:row.category,staticClass:"cashflow-table-value-row",on:{"click":function($event){return _vm.emitRowClick(row)}}},_vm._l((_vm.cashflowColumns),function(col,i){return _c('td',{key:col.value,class:{
            rborder: true,
            'deep-orange darken-3': _vm.shouldHighLight(row, col),
            'rounded-pill': _vm.shouldHighLight(row, col),

            'cashflow-table-value-cell': true,
            'sticky-left': i === 0,
          }},[(_vm.isToolTipNeeded(col.value, row[col.value]))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({class:'font-weight-light',style:('margin: 0px;')},'p',attrs,false),on),[_c('span',{class:{
                    'value-span': true,
                    'font-weight-bold': _vm.shouldBoldify(row),
                    'red-text': _vm.shouldHighLight(row, col)
                      ? false
                      : _vm.shouldRedify(row, col),
                    'white--text': _vm.shouldHighLight(row, col),
                    'green-text': _vm.shouldGreenify(row, col),
                  }},[_vm._v(" "+_vm._s(row[col.value])+" ")])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.cashflowFormulas[row[col.value]]))])]):(col.value == 'total/ha')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({class:'font-weight-light',style:('margin: 0px;')},'p',attrs,false),on),[_c('span',{class:{
                    'value-span': true,
                    'font-weight-bold': _vm.shouldBoldify(row),
                    'red-text': _vm.shouldHighLight(row, col)
                      ? false
                      : _vm.shouldRedify(row, col),
                    'white--text': _vm.shouldHighLight(row, col),
                    'green-text': _vm.shouldGreenify(row, col),
                  }},[_vm._v(" "+_vm._s(row[col.value])+" ")])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.totalHaToolTip))])]):_c('span',{class:{
              'value-span': true,
              'font-weight-bold': _vm.shouldBoldify(row),
              'red-text': _vm.shouldHighLight(row, col)
                ? false
                : _vm.shouldRedify(row, col),
              'white--text': _vm.shouldHighLight(row, col),
              'green-text': _vm.shouldGreenify(row, col),
            }},[_vm._v(" "+_vm._s(_vm.parseRowValue(row[col.value]))+" ")])],1)}),0)}),0)]):_c('div',{staticClass:"d-flex flex-row align-center my-2 justify-center"},[_c('span',{staticClass:"overline text--muted grey--text"},[_vm._v("No fue posible mostrar la tabla")])])])}
var staticRenderFns = []

export { render, staticRenderFns }